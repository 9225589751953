export default [

  {
    parent: 'Plataforma',
    horizontalIcon: 'DatabaseIcon',
    title: 'Branding',
    route: 'branding',
    icon: 'palette',
  },
]
