export default [
  {
    parent: 'Avatars',
    horizontalIcon: 'TagIcon',
    title: 'Avatares',
    route: 'profile-avatar',
    icon: 'user-group',
  },

]
