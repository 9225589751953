export default [
  {
    parent: 'Plataforma',
    horizontalIcon: 'DatabaseIcon',
    title: 'Subgroups',
    route: 'subgroups',
    icon: 'users',
  },

]
