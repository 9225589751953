export default [
  {
    title: 'Códigos promocionales',
    icon: 'coins',

    children: [
      {
        title: 'codes',
        route: 'promotional-codes',
        icon: 'coin',
      },
      {
        title: 'uses',
        route: 'use-codes',
        icon: 'check',
      },
    ],
  },
]
