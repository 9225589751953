<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link class="ml-25" href="https://www.plenitas.com/" target="_blank">
        <b-img :src="require('@/assets/images/logo/logo_blanco_Plenitas_formerly.png')" style="width: 150px"
        alt="logo" /></b-link>
        <span class="d-none d-sm-inline-block ml-1">
          COPYRIGHT © {{ new Date().getFullYear() }}, all rights Reserved</span>
    </span>

    <span name="Beta" class="float-md-right d-none d-md-block">{{ version }}
    </span>
  </p>
</template>

<script>
import { BLink,BImg} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg
  },
  data() {
    return {
      version: "",
    }
  },
  mounted() {
    
    this.version = process.env.VUE_APP_VERSION

  },
}
</script>
