export default [{
  header: 'confSuperuser',
},

{
  title: 'Superusuario',
  icon: 'user-crown',

  children: [
    {
      title: 'Tokens',
      route: 'tokens',
      icon: 'key',
    },
    {
      title: 'Almacenamiento',
      route: 'bucket',
      icon: 'cloud',
    },
    {
      title: 'Publicidad',
      route: 'ads',
      icon: 'desktop',
    },
    {
      title: 'modelo',
      route: 'service',
      icon: 'money-check-dollar',
    },
    {
      title: 'Directos',
      route: 'live',
      icon: 'signal-stream',
    },
    {
      title: 'transcoding.transcode',
      route: 'transcoding',
      icon: 'file-video',
    },
    {
      title: 'Correo',
      route: 'mail',
      icon: 'envelope',
    },
    {
      title: 'Plantillas',
      route: 'templates',
      icon: 'file-invoice',
    },
    {
      title: 'sistemForm',
      route: 'verificator',
      icon: 'user-check',
    },
    {
      title: 'Plataformas',
      route: 'create-platform',
      icon: 'file-check',
    },
    {
      title: 'Social Login',
      route: 'social-login',
      icon: 'hashtag',
    },
    {
      title: 'permissionGroups',
      route: 'permission-groups',
      icon: 'user-lock',
    },
  ].sort((a, b) => a.title.localeCompare(b.title)),
},
]
