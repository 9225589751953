export default [
  {
    parent: 'Avatars',
    horizontalIcon: 'TagIcon',
    title: 'Categorias',
    route: 'avatar-category',
    icon: 'images-user',
  },

]
