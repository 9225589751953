export default [
  {
    parent: 'Usuarios',
    horizontalIcon: 'UserIcon',
    title: 'Administrators',
    route: 'administrators-list',
    icon: 'user-tie',
  },

]
