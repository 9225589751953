<template>
  <ul>
    <component :is="resolveNavItemComponent(item)" v-for="item in menu" :key="item.header || item.title" :item="item" />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import metrics from '/src/navigation/full/metrics/metrics'
import cdn from '/src/navigation/full/metrics/cdn'
import analytics from '/src/navigation/full/metrics/analytics'
import superuser from "/src/navigation/full/superuser";
import dashboard from "/src/navigation/full/dashboard";
import { getUserData } from "@/auth/utils";
import { checkPermissions, findPermissionsByName, } from "@/store/functions";
import permissionMap from '@/store/permissionMap';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      menu: [],

    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.itemsMenu();

  },
  methods: {
    handleResize() {
      this.$store.commit('app/SET_SIZE_WINDOW', window.innerWidth)
    },
    checkAndAddHeader(permission, array, blockHeaders, addedHeaders) {
      for (const [header, permissions] of Object.entries(blockHeaders)) {
        if (permissions.includes(permission) && !addedHeaders.has(header)) {
          array.push({ header });
          addedHeaders.add(header); // Marcar como añadido para evitar duplicados
        }
      }
    },
    itemsMenu() {
      try {
        const userData = getUserData()
        let array = [];
        let isShop = false
        let hasProfiles = false
        let isSuperuser = false
        let isFastChannel = true
        let isAds = false
        let hasReels = false
        let isPromotionalCodes = false
        let hasMyChannel = false
        let geoipbloking = false
        let isCountlyActive = false
        let isHbbtv = false
        let hasDisplayAds = false
        let hasSubgroups = false

        isPromotionalCodes = userData?.profile?.client?.isPromotionalCodes
        geoipbloking = userData?.profile?.client?.geoipbloking
        hasMyChannel = userData?.profile?.client?.hasMyChannel
        hasProfiles = userData?.profile?.client?.hasProfiles
        isCountlyActive = userData?.profile?.client?.isCountlyActive
        isFastChannel = userData?.profile?.client?.isFastChannel
        hasDisplayAds = userData?.profile?.client?.hasDisplayAds
        hasSubgroups = userData?.profile?.client?.hasSubgroups

        isShop = userData?.profile?.client?.isShop
        isAds = userData?.profile?.client?.isAds
        isHbbtv = userData?.profile?.client?.isHbbtv
        isSuperuser = userData?.isSuperuser
        hasReels = userData?.profile?.client?.hasReels
        isSuperuser ? array.push(...superuser) : ''
        array.push(...dashboard,);
        const specialConditions = {
          'contents.menu_cms_fastchannel': isFastChannel,
          'contents.menu_cms_reel': hasReels,
          'showroom.menu_cms_category_showroom': isShop,
          'showroom.menu_cms_vendor': isShop,
          'shop.menu_cms_order': isShop,
          'showroom.menu_cms_refund': isShop,
          'showroom.menu_cms_withdrawal': isShop,
          'ads.menu_cms_campaing': isAds,
          'ads.view_displayad': hasDisplayAds,
          'users.add_subgroup': hasSubgroups,
          'contents.menu_cms_category_character': hasProfiles,
          'contents.menu_cms_character': hasProfiles,
          'users.menu_cms_character_settings': hasProfiles,
          'users.menu_cms_hbbtv': isHbbtv,
          'contents.menu_cms_mychannel': hasMyChannel,
          'contents.menu_cms_countrygroup': geoipbloking,
          'contents.menu_cms_promotionalcode': isPromotionalCodes,
        };

        function addMetricsPlatform(array, children) {
          return [
            { header: 'Metrics Platform' },
            {
              title: 'Metrics',
              icon: 'chart-mixed',
              children,
            },
          ]
        }

        if (checkPermissions('users.menu_cms_metrics') || checkPermissions('users.menu_cms_analytics')) {
          const metricsChildren = [];

          // Agregar "Plataforma" y "CDN" si tiene permiso para métricas
          if (checkPermissions('users.menu_cms_metrics')) {
              metricsChildren.push({
                title: 'CDN', route: 'dashboard-cdn',
                icon: 'database',

              });

            // "Plataforma" solo si tiene permiso y está activo Countly
            if (isCountlyActive) {
              metricsChildren.push({ title: 'Plataforma', route: 'dashboard-statistics',
              icon: 'table',
               });
            }
          }

          // Verificar si debe incluir "Analytics"
          const matchingPermissions = findPermissionsByName('menu_cms');

          if (
            matchingPermissions.length > 1 && // Tiene más de un permiso relacionado con CMS
            matchingPermissions.includes('users.menu_cms_analytics') // Y uno de ellos es "users.menu_cms_analytics"
          ) {
            metricsChildren.push({ title: 'Analytics', route: 'dashboard-metrics',icon: 'file-chart-column', });
          }

          // Agregar al array principal si hay children
          if (metricsChildren.length > 0) {
            array.push(...addMetricsPlatform(array, metricsChildren));
          }
        }
        const blockHeaders = {
          'Contents': ['contents.menu_cms_category', 'contents.menu_cms_podcast', 'contents.menu_cms_live', 'contents.menu_cms_resource', 'contents.menu_cms_file', 'contents.menu_cms_reel', 'contents.menu_cms_epg'],
          'Fast Channels': ['contents.menu_cms_fastchannel'],
          'Shop': ['showroom.menu_cms_category_showroom', 'showroom.menu_cms_vendor', 'shop.menu_cms_order', 'showroom.menu_cms_refund', 'showroom.menu_cms_withdrawal'],
          'Campañas': ['ads.menu_cms_campaing', 'ads.view_displayad'],
          'Avatars': ['contents.menu_cms_category_character', 'contents.menu_cms_character', 'users.menu_cms_character_settings'],
          'Usuarios': ['users.menu_cms_profile', 'users.menu_cms_vuser'],
          'HBBTV': ['users.menu_cms_hbbtv'],
          'Plataforma': ['users.menu_cms_settings', 'users.menu_cms_branding', 'contents.menu_cms_menu', 'devices.menu_cms_notification', 'contents.menu_cms_slider', 'contents.menu_cms_mychannel', 'users.add_subgroup', 'contents.menu_cms_countrygroup', 'contents.menu_cms_promotionalcode', 'contents.menu_cms_inapppurchase', 'users.menu_cms_subscription'],
        };
        const addedHeaders = new Set();
        // Iterar sobre el mapa de permisos
        for (const [permission, data] of Object.entries(permissionMap)) {
          // Verificar si el usuario tiene el permiso
          if (isSuperuser || checkPermissions(permission)) {
            // Si el permiso requiere una condición especial
            if (specialConditions.hasOwnProperty(permission)) {
              // Si se cumple la condición especial
              if (specialConditions[permission]) {
                this.checkAndAddHeader(permission, array, blockHeaders, addedHeaders);
                array.push(...data);
              }
            } else {
              // Para los permisos que no requieren condiciones especiales
              this.checkAndAddHeader(permission, array, blockHeaders, addedHeaders);
              array.push(...data);
            }
          }
        }



        this.menu = array.slice();
      } catch (error) {
        console.log(error)

      }
    },

  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent
    };
  }
};
</script>
