export default [
  {
    parent: 'Plataforma',
    horizontalIcon: 'DatabaseIcon',
    title: 'Notifications',
    route: 'notifications',
    icon: 'message',
  },

]
