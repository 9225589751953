export default [
  {
    parent: 'Contents',
    horizontalIcon: 'FileTextIcon',
    title: 'Media',
    route: 'contents-media',
    icon: 'film',
  },

]
