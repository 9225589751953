export default [
  {
    parent: 'Plataforma',
    horizontalIcon: 'DatabaseIcon',
    title: 'Settings',
    route: 'settings',
    icon: 'gear',
  },

]
